<template>
  <div class="credit-scoring-container">
    <router-view></router-view>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'CreditScoring',
  
  created() {
    // Toggle sidebar visibility to match the behavior in Apps.vue
    this.TOGGLE_SIDEBAR_VISIBILITY(false);
    // Reset sidebar routes if needed
    this.SET_SIDEBAR_ROUTES([]);
  },
  
  methods: {
    ...mapMutations([
      "TOGGLE_SIDEBAR_VISIBILITY",
      "SET_SIDEBAR_ROUTES"
    ])
  }
}
</script>

<style scoped>
.credit-scoring-container {
  height: 100%;
  width: 100%;
}
</style>